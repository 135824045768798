export * from './address';
export * from './login-body';
export * from './login-response';
export * from './many-news';
export * from './news';
export * from './ecg';
export * from './team';
export * from './many-cardio3D';
export * from './cardio3D';
export * from './many-interactiveImages';
export * from './interactiveImages';
export * from './many-development';
export * from './many-ecg';
export * from './development';
export * from './dicom';
export * from './realCases';
export * from './many-realCases';
export * from './cartoCases';
export * from './many-cartoCases';
export * from './register-body';
export * from './register-response';
export * from './scienceInsights';
export * from './many-scienceInsights';